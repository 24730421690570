<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="manualInvoiceDialog" max-width="500">
      <v-card>
        <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
          Manual Invoice to HRDC
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to submit this Manual Invoice
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalManualInvoice()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="manualInvoiceDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--BOC:[contents]-->

    <v-card>
      <div>
        <v-toolbar color="#063058">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="hrdcData.isTaxpodAdminRoles && !btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only"
                  color="white"
                  :loading="apiSubmit.isLoading"
                  @click="manualInvoiceDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Upload Manual Invoice to HRDC</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <v-expansion-panels
        class="pa-2"
        v-model="invoicePanel"
        multiple
        focusable
      >
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <span class="font-weight-bold"> HRDC Invoice Request </span>
            <template v-slot:actions>
              <v-icon color="primary"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- Normal -->
            <v-row class="pt-3" v-show="applicationDetails.refundType == 1">
              <v-col cols="12" class="d-flex justify-space-between">
                <div class="col-10 pa-0">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <span class="font-weight-bold"> {{ name4 }} </span>
                    </v-col>
                  </v-row>

                  <!-- Price -->
                  <v-row>
                    <v-col cols="5" class="pt-0 pb-0">
                      <span class="font-weight-bold"> Price: </span>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                      <span>
                        {{ finalRequestAmount }}
                      </span>
                    </v-col>
                  </v-row>

                  <!-- Event name -->
                  <v-row>
                    <v-col cols="5" class="pt-0 pb-0">
                      <span class="font-weight-bold"> Event Name: </span>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                      <span>
                        {{ invoiceRequest.programmeName }}
                      </span>
                    </v-col>
                  </v-row>

                  <!-- Date (Day) -->
                  <v-row>
                    <v-col cols="5" class="pt-0 pb-0">
                      <span class="font-weight-bold"> Date (Day): </span>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                      <span>
                        {{ formattedDateOfCourse.dateOfCourseStart }} to
                        {{ formattedDateOfCourse.dateOfCourseEnd }}
                      </span>
                    </v-col>
                  </v-row>

                  <!-- Company Name -->
                  <v-row>
                    <v-col cols="5" class="pt-0 pb-0">
                      <span class="font-weight-bold"> Company Name: </span>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                      <span>
                        {{ invoiceRequest.companyName }}
                      </span>
                    </v-col>
                  </v-row>

                  <!-- Grant No -->
                  <v-row>
                    <v-col cols="5" class="pt-0">
                      <span class="font-weight-bold"> Grant No: </span>
                    </v-col>
                    <v-col class="pt-0 pb-0">
                      <span>
                        {{ invoiceRequest.grantId }}
                      </span>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <v-icon
                    style="cursor: pointer"
                    color="blue"
                    @click="copyContent(invoiceRequest)"
                  >
                    mdi-content-copy
                  </v-icon>
                </div>
              </v-col>
            </v-row>

            <!-- 70/30 -->
            <v-row
              class="pt-3"
              cols="12"
              v-show="applicationDetails.refundType == 2"
            >
              <v-col cols="6">
                <v-app-bar flat outlined elevation="0">
                  <span class="text-button font-weight-bold"
                    >70% Claim Amount</span
                  >
                </v-app-bar>

                <v-col cols="12" class="d-flex justify-space-between pl-0">
                  <div>
                    {{ name4 }} <br />
                    Price: {{ applicationDetails.seventyPercentPrice }}<br />
                    Event Name: {{ invoiceRequest.programmeName }}<br />
                    Date (Day): {{ formattedDateOfCourse.dateOfCourseStart }} to
                    {{ formattedDateOfCourse.dateOfCourseEnd }}<br />
                    Company Name: {{ invoiceRequest.companyName }}<br />
                    Grant No: {{ invoiceRequest.grantId }}<br />
                  </div>
                  <div>
                    <v-icon
                      style="cursor: pointer"
                      color="blue"
                      @click="copyContent(invoiceRequest)"
                    >
                      mdi-content-copy
                    </v-icon>
                  </div>
                </v-col>
              </v-col>

              <v-col cols="6">
                <v-app-bar flat outlined elevation="0">
                  <span class="text-button font-weight-bold"
                    >30% Claim Amount</span
                  >
                </v-app-bar>
                <v-col cols="12" class="d-flex justify-space-between pl-0">
                  <div>
                    {{ name4 }} <br />
                    Price: {{ applicationDetails.thirtyPercentPrice }}<br />
                    Event Name: {{ invoiceRequest.programmeName }}<br />
                    Date (Day): {{ formattedDateOfCourse.dateOfCourseStart }} to
                    {{ formattedDateOfCourse.dateOfCourseEnd }}<br />
                    Company Name: {{ invoiceRequest.companyName }}<br />
                    Grant No: {{ invoiceRequest.grantId }}<br />
                  </div>
                  <div>
                    <v-icon
                      style="cursor: pointer"
                      color="blue"
                      @click="copyContent(invoiceRequest)"
                    >
                      mdi-content-copy
                    </v-icon>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div>
        <v-row>
          <v-col cols="6" style="border-right: 1px solid lightgrey">
            <!-- HRDC Invoice File -->
            <v-col>
              <div
                v-cloak
                @drop.prevent="addDropFile($event, 'hrdcInvoiceFileUrl')"
                @dragover.prevent
                class="w-100"
              >
                <v-file-input
                  :prepend-icon="null"
                  prepend-inner-icon="mdi-paperclip"
                  dense
                  filled
                  small-chips
                  label="HRDC Invoice File"
                  accept=".pdf"
                  @change="
                    scanPdf($event, 'hrdcInvoiceFileUrl', 'hrdcInvoiceNo')
                  "
                  v-model="formData.hrdcInvoiceFileUrl"
                  :error-messages="errorField.hrdcInvoiceFileUrl"
                  :truncate-length="1000"
                ></v-file-input>
              </div>
            </v-col>

            <!--HRDC Invoice No.-->
            <v-col>
              <v-text-field
                dense
                filled
                label="HRDC Invoice No."
                v-model="formData.hrdcInvoiceNo"
                :error-messages="errorField.hrdcInvoiceNo"
              ></v-text-field>
            </v-col>

            <!--Client No. XERO-->
            <v-col>
              <v-text-field
                dense
                filled
                label="Client No. XERO"
                v-model="formData.clientNoXero"
                :error-messages="errorField.clientNoXero"
              ></v-text-field>
            </v-col>

            <!--Invoice Date (Optional)-->
            <v-col>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formData.invoiceDate"
                    label="Invoice Date (YYYY-MM-DD)"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.invoiceDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-col>

          <v-col cols="6">
            <!-- 70/30 Case -->
            <v-col>
              <div
                v-cloak
                @drop.prevent="
                  addDropFile($event, 'hrdcInvoiceFileUrl2', 'hrdcInvoiceNo2')
                "
                @dragover.prevent
                class="w-100"
                v-show="this.applicationDetails.refundType == 2"
              >
                <v-file-input
                  :prepend-icon="null"
                  prepend-inner-icon="mdi-paperclip"
                  dense
                  filled
                  small-chips
                  label="HRDC Invoice File (30%)"
                  accept=".pdf"
                  @change="
                    scanPdf($event, 'hrdcInvoiceFileUrl2', 'hrdcInvoiceNo2')
                  "
                  v-model="formData.hrdcInvoiceFileUrl2"
                  :error-messages="errorField.hrdcInvoiceFileUrl2"
                  :truncate-length="1000"
                ></v-file-input>
              </div>
            </v-col>

            <!-- 70/30 Case -->
            <v-col>
              <v-text-field
                dense
                filled
                label="HRDC Invoice No (30%)."
                v-model="formData.hrdcInvoiceNo2"
                :error-messages="errorField.hrdcInvoiceNo2"
                v-show="this.applicationDetails.refundType == 2"
              ></v-text-field>
            </v-col>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    formattedDateOfCourse() {
      return {
        dateOfCourseStart: this.convertDateFormat(
          this.invoiceRequest.dateOfCourseStart
        ),
        dateOfCourseEnd: this.convertDateFormat(
          this.invoiceRequest.dateOfCourseEnd
        ),
      };
    },
    finalRequestAmount() {
      let requestAmount = this.invoiceRequest.price;
      if (this.invoiceRequest.requestManualInvoiceAmount) {
        requestAmount = this.invoiceRequest.requestManualInvoiceAmount;
      }

      return `RM ${this.formatNumber(requestAmount)}`;
    },
  },
  props: ["params"],
  data: () => ({
    apiSubmit: new Api(),
    apiScanPdfText: new Api(),
    apiGetApplicationDetails: new Api(),
    apiGetApplicationManualInvoice: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    passSubscriptionEndDate: true,
    loadingDialog: false,
    formData: {
      applicationNo: null,
      clientNoXero: null,
      hrdcInvoiceNo: null,
      hrdcInvoiceFileUrl: null,
      invoiceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      // 70/30 case
      hrdcInvoiceFileUrl2: null,
      hrdcInvoiceNo2: null,
    },
    errorField: {
      applicationNo: null,
      clientNoXero: null,
      hrdcInvoiceNo: null,
      hrdcInvoiceFileUrl: null,
      invoiceDate: null,
      hrdcInvoiceFileUrl2: null,
      hrdcInvoiceNo2: null,
    },
    manualInvoiceDialog: false,
    invoicePanel: [0],
    invoiceRequest: {
      companyName: null,
      programmeName: null,
      price: null,
      requestManualInvoiceAmount: null,
      grantId: null,
      dateOfCourseStart: null,
      dateOfCourseEnd: null,
    },
    applicationDetails: {
      refundType: null,
      seventyPercentPrice: null,
      thirtyPercentPrice: null,
    },
    editMode: false,
    btnDisabled: false,
  }),
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/manual_invoice/create-manual-invoice`,
        method: "post",
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/manual_invoice/scan-pdf`,
        method: "post",
      },
      apiGetApplicationDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-details`,
        method: "post",
        params: {
          applicationUuid: this.formData.applicationNo,
        },
      },
      apiGetApplicationManualInvoice: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-manual-invoice`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].query !== undefined) {
        this[api].setQuery(apiDetails[api].query);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetApplicationDetails.fetch();
    this.apiGetApplicationDetails.setCallbackCompleted((response) => {
      try {
        // Just take first row to display basic info.
        const { data } = response;
        const coureSubscriptionDate = response.applicationLog.find(
          (item) =>
            item.stageId ==
            this.hrdcData.stagePriority.subscriptionDateScheduled
        );
        let cSubStartDate = null;
        let cSubEndDate = null;
        if (coureSubscriptionDate) {
          cSubStartDate = coureSubscriptionDate.startDate;
          cSubEndDate = coureSubscriptionDate.endDate;
        }

        this.invoiceRequest.companyName = data.oriCompanyName.toUpperCase();
        this.invoiceRequest.programmeName = data.programmeName;
        this.invoiceRequest.price = data.totalPrice;
        this.invoiceRequest.requestManualInvoiceAmount =
          data.requestManualInvoiceAmount;
        this.invoiceRequest.grantId = data.grantApprovalNo ?? "-";
        this.invoiceRequest.dateOfCourseStart = cSubStartDate;
        this.invoiceRequest.dateOfCourseEnd = cSubEndDate;

        // Application Details.
        this.applicationDetails.refundType = data.refundType;
        this.applicationDetails.seventyPercentPrice =
          "RM " + this.formatNumber(data.seventyPercentPrice);
        this.applicationDetails.thirtyPercentPrice =
          "RM " + this.formatNumber(data.thirtyPercentPrice);

        // Get invoice date & file details.
        this.apiGetApplicationManualInvoice.setCallbackCompleted((response) => {
          try {
            const { status, data } = response;
            let hrdcInvoiceFile = null;
            let hrdcInvoiceFile2 = null;
            let clientNoXero = null;
            let hrdcInvoiceNo = null;
            let hrdcInvoiceNo2 = null;
            if (status) {
              // Check form revert status must be complete in order to proceed.
              if (!data.revertFormStatus) {
                if (data.masterlistMigrate) {
                  this.btnDisabled = false;
                } else {
                  this.btnDisabled = true;
                  this.alertError.push("JD14 & T3 Form is not complete!");
                }
              }

              for (const item of data.ApplicationAttachment) {
                // Create a Blob object from the data
                const blob = new Blob([new Uint8Array(item.dataArray)]);

                // Create the File object
                const file = new File([blob], item.fileName, {
                  type: "application/pdf",
                });

                switch (item.identifier) {
                  // Normal case.
                  case "Manual Invoice":
                    hrdcInvoiceFile = file;
                    break;
                  // 70% case.
                  case 0:
                    hrdcInvoiceFile = file;
                    break;
                  // 30% case.
                  case 1:
                    hrdcInvoiceFile2 = file;
                    break;
                }
              }

              clientNoXero = data.clientNoXero;
              hrdcInvoiceNo = data.manualInvoiceToHrdc;
              hrdcInvoiceNo2 = data.manualInvoiceToHrdc2;

              if (data.ApplicationLog.length > 0) {
                this.formData.invoiceDate = moment(
                  data.ApplicationLog[0].startDate
                ).format("YYYY-MM-DD");
              }
            }

            this.formData.hrdcInvoiceFileUrl = hrdcInvoiceFile;
            this.formData.hrdcInvoiceFileUrl2 = hrdcInvoiceFile2;
            this.formData.clientNoXero = clientNoXero;
            this.formData.hrdcInvoiceNo = hrdcInvoiceNo;
            this.formData.hrdcInvoiceNo2 = hrdcInvoiceNo2;

            this.hideLoadingDialog();
          } catch (err) {
            console.log(err);
          }
        });
        this.apiGetApplicationManualInvoice.fetch();
      } catch (err) {
        console.log(err);
      }
    });

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[
        this.$store.state[this.service.key].data.stageId
      ];

    this.hrdcData.rolesAssignation(this.auth.roles);
    if (!this.hrdcData.isTaxpodAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGte(
      currentStageId,
      this.hrdcData.stageSlug.manualInvoiceToHrdc
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.clientRevertT3Form
    );
  },
  mounted() {},
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    formatNumber(number) {
      if (number) {
        return number.toLocaleString();
      }
    },
    convertDateFormat(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD HH:mm:ssZ").format("D MMMM YYYY");
      }

      return "-";
    },
    copyContent(item) {
      const dateOfCourse = `${this.formattedDateOfCourse.dateOfCourseStart} to ${this.formattedDateOfCourse.dateOfCourseEnd}`;
      const contentToCopy = `Event Name: ${item.programmeName}\nDate (Day): ${dateOfCourse}\nCompany Name: ${item.companyName}\nGrant No: ${item.grantId}`;

      // Create a temporary textarea element
      const textarea = document.createElement("textarea");
      textarea.value = contentToCopy;
      document.body.appendChild(textarea);

      // Select the text in the textarea
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
      });
    },
    // Drag & Drop file function.
    addDropFile(e, key) {
      let file = e.dataTransfer.files[0];

      if (!file.type.match("pdf.*")) {
        this.errorField[key] = "Please select pdf file format";
        return;
      }

      this.formData[key] = file;

      switch (key) {
        case "hrdcInvoiceFileUrl":
          this.scanPdf(true, key, "hrdcInvoiceNo");
          break;

        case "hrdcInvoiceFileUrl2":
          this.scanPdf(true, key, "hrdcInvoiceNo2");
          break;

        default:
          break;
      }
    },
    scanPdf($event, type, invoiceType) {
      if ($event) {
        this.showLoadingDialog();
        const fd = new FormData();
        fd.append("hrdcInvoiceFileUrl", this.formData[type]);

        this.apiScanPdfText.setParams(fd);
        this.apiScanPdfText.fetch();
        this.apiScanPdfText.setCallbackCompleted((response) => {
          try {
            if (response.status) {
              this.formData[invoiceType] = response.data.invoice;
              this.formData.clientNoXero = response.data.clientNo;

              const parsedDate = moment(
                response.data.invoiceDate,
                "DD MMMM YYYY"
              );
              const formattedDate = parsedDate.format("YYYY-MM-DD");
              this.formData.invoiceDate = formattedDate;
            } else {
              this.$store.dispatch("showMessage", {
                message: response.data,
                messageType: "error",
                timeout: 2000,
              });
            }

            this.hideLoadingDialog();
          } catch (err) {
            console.log(err);
          }
        });
      }
    },
    //EOC
    modalManualInvoice() {
      this.manualInvoiceDialog = !this.manualInvoiceDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcEinvoicingApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state[this.service.key].data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    async submit() {
      this.showLoadingDialog();
      // Generate Form Data to handle multipart/form-data
      const fd = new FormData();
      fd.append("applicationUuid", this.formData.applicationNo);
      fd.append("clientNoXero", this.formData.clientNoXero);
      fd.append("hrdcInvoiceNo", this.formData.hrdcInvoiceNo);
      fd.append("hrdcInvoiceFileUrl", this.formData.hrdcInvoiceFileUrl);
      fd.append("hrdcInvoiceNo2", this.formData.hrdcInvoiceNo2);
      fd.append("hrdcInvoiceFileUrl2", this.formData.hrdcInvoiceFileUrl2);
      fd.append("refundType", this.applicationDetails.refundType);
      fd.append("invoiceDate", this.formData.invoiceDate);
      fd.append("referById", this.auth.uuid);
      fd.append("editMode", this.editMode);

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.applicationNotFound) {
              this.$store.dispatch("showMessage", {
                message: response.data.applicationNo,
                messageType: "error",
                timeout: 2000,
              });
            }
          }
          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcEinvoicingApplication",
              query: {
                viewId: this.$store.state[this.service.key].data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
<style scoped>
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}

th,
td {
  padding-left: 5px;
  padding-right: 5px;
}
</style>
